<template>
  <el-main>
    <div class="searchdiv">
      <div>
        <el-button size="mini" type="primary" @click="addnode" icon="el-icon-plus">添加类目</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table
          ref="multipleTable"
          border
          size="mini"
          :data="nodelist"
          tooltip-effect="dark"
          style="width: 100%"
          row-key="id"
          border
          default-expand-all
          :tree-props="{children: 'children'}">
        <el-table-column label="类目名称" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="类目备注" prop="remarks">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'" @click="setenable(scope.row)">
              {{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="180" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" icon="el-icon-plus" @click="addnode(scope.row,'nodeform')" v-if="scope.row.pid == 0">子类目</el-link>
            <el-link class="optlink" @click="editnode(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" @click="delnode(scope.row)">{{ $t('global.delete') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="nodeformdata.id?'修改类目':'添加类目'" :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form size="mini" :model="nodeformdata" :rules="rules" ref="nodeform" :show-message="false">
        <el-form-item label="pid" label-width="120px" prop="pid" required>
          <el-select v-model="nodeformdata.pid" placeholder="parent" @change="this.$forceUpdate()">
            <el-option :key="0" :label="'顶级节点'" :value="0">顶级节点</el-option>
            <el-option
                v-for="item in parentlist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              <span v-html="item.name"></span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类目名称" label-width="120px" prop="name">
          <el-input v-model="nodeformdata.name"></el-input>
        </el-form-item>
        <el-form-item label="类目状态" label-width="120px">
          <el-switch v-model="nodeformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')"
                     :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item label="类目备注" label-width="120px" prop="remarks">
          <el-input type="textarea" v-model="nodeformdata.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button size="mini" type="primary" @click="dosubmit('nodeform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
// @ is an alias to /src
import {ORDER_CODE} from "@/libs/const";

export default {
  name: 'node',
  components: {},
  data() {
    return {
      nodelist: [],
      parentlist: [],
      totalRows: 0,
      defaultnodeinfo: {
        id: 0,
        name: '',
        status: 1,
        remarks: '',
        pid: 0
      },
      nodeformdata: {},
      rules: {},
      isshowdialog: false,
      isbusy: false,
    }
  },
  methods: {
    async getnodelist() {
      this.isbusy = true;
      let res = await this.httpget('/system/category/index');
      if (res.code === 0) {
        this.nodelist = res.data;
        this.parentlist = this.tree_to_array(JSON.parse(JSON.stringify(res.data)), 0);
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    },
    editnode(item) {
      this.nodeformdata = null;
      this.nodeformdata = this.cloneData(item);
      this.isshowdialog = true;
    },
    addnode(item, formName) {
      if (item) {
        this.nodeformdata.pid = item.id;
        this.$forceUpdate();
      }
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.nodeformdata = this.cloneData(this.defaultnodeinfo);
        this.$refs[formName].resetFields();
      });
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/category/save', this.nodeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getnodelist();
        this.nodeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/category/setenable', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getnodelist();
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async setmenu(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/category/setmenu', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getnodelist();
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async delnode(item) {
      this.$confirm('Are you sure to delete this item?').then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/category/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getnodelist();
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    tree_to_array(treelist, level) {
      level = level || 0;
      let newtreelist = [];
      treelist.forEach((tree) => {
        var str = '';
        for (var i = 0; i < level; i++) {
          str += '&nbsp;&nbsp;';
        }
        str += level > 0 ? '└&nbsp;' : '';
        tree.label = tree.title;
        tree.title = str + tree.title;
        if (tree.children) {
          var children = this.cloneData(tree.children);
          tree.children = undefined;
          newtreelist.push(tree);
          /*var tmptreelist = this.tree_to_array(children, level + 1);
          tmptreelist.forEach((tmptree) => {
            newtreelist.push(tmptree);
          })*/
        } else {
          newtreelist.push(tree);
        }
      })
      return newtreelist;
    }
  },
  mounted() {
    this.getnodelist();
  },
  computed: {
    userinfo: {
      get: function () {
        return this.$store.getters.getUserInfo;
      }
    },
    currLang: {
      get: function () {
        return this.$store.getters.getCurrLang || 'cn';
      },
      set(val) {
        this.$store.commit('setCurrLang', val);
      }
    },
  },
}
</script>